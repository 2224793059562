<template>
  <el-dialog
    v-model="roleDisplay"
    :title="ruleForm.roleId ? '修改角色' : '添加角色'"
    width="30%"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      status-icon
    >
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
      <el-form-item label="角色描述" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea" />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm(ruleFormRef)"> 取消</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { addRoleAPI, roleSaveAPI } from '@/api/organization'
import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },
  getRoleList: {
    type: Function
  },
  editItem: {
    type: Object
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const roleDisplay = useVModel(props) // 得到一个响应式的数据，可以直接修改

watch(
  () => props.editItem,
  (val) => {
    ruleForm.value = val
    console.log(val)
  },
  {
    deep: true
  }
)

/** 表单 S */
const ruleFormRef = ref(null)
const ruleForm = ref({
  name: '',
  remark: ''
})
const rules = {
  name: [
    {
      required: true,
      message: '请输入角色名称',
      trigger: 'blur'
    }
  ],
  remark: [
    {
      required: true,
      message: '请输入角色描述',
      trigger: 'blur'
    }
  ]
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      if (ruleForm.value.roleId) {
        await roleSaveAPI(ruleForm.value)
        ElMessage.success('角色修改成功')
      } else {
        await addRoleAPI(ruleForm.value)
        ElMessage.success('角色添加成功')
      }
      props.getRoleList()
      ruleForm.value = {
        name: '',
        remark: ''
      }
      roleDisplay.value = false
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  ruleForm.value = {
    name: '',
    remark: ''
  }
  props.getRoleList()
  roleDisplay.value = false
}
</script>

<style></style>
