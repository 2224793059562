<template>
  <div class="">
    <el-button type="primary" style="margin-bottom: 20px" @click="onAddRole">
      添加角色
    </el-button>

    <el-card>
      <el-table
        :data="allRoles"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#f2f2f2' }"
      >
        <el-table-column label="序号" type="index" width="120">
        </el-table-column>
        <el-table-column label="名称" prop="name"> </el-table-column>
        <el-table-column label="描述" prop="remark"> </el-table-column>
        <el-table-column label="操作" prop="action" width="260">
          <template #default="{ row }">
            <el-button type="primary" size="small" @click="editBtn(row)">
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="onAssignPermissions(row)"
            >
              分配权限
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加角色 -->
    <add-role
      v-model="roleDisplay"
      :getRoleList="getRoleList"
      :editItem="editItem"
    >
    </add-role>

    <!-- 角色分配权限 -->
    <assign-permissions
      v-model="distributePermissionVisible"
      :roleId="RoleId"
    ></assign-permissions>
  </div>
</template>

<script setup>
import { roleList } from '@/api/organization'
import { ref, watch } from 'vue'
import addRole from './components/addRole.vue'
import assignPermissions from './components/assignPermissions.vue'

/** 表格 S */
const allRoles = ref([])
const getRoleList = async () => {
  allRoles.value = await roleList()
}
getRoleList()

// 修改
const editItem = ref([])
const editBtn = (row) => {
  roleDisplay.value = true
  editItem.value = row
}

// 权限
const distributePermissionVisible = ref(false)
const RoleId = ref('')
const onAssignPermissions = ({ roleId }) => {
  RoleId.value = roleId
  distributePermissionVisible.value = true
}

watch(distributePermissionVisible, (val) => {
  if (!val) RoleId.value = ''
})
/** 表格 E */

/** 角色 S */
const roleDisplay = ref(false)

// 新增
const onAddRole = () => {
  editItem.value = {
    name: '',
    remark: ''
  }
  roleDisplay.value = true
}
/** 角色 E */
</script>

<style lang="scss" scoped></style>
