<template>
  <el-dialog title="配置角色" :model-value="modelValue" @close="closed">
    <el-tree
      ref="treeRef"
      :data="allPermission"
      show-checkbox
      :check-strictly="false"
      node-key="menuId"
      :props="defaultProps"
    >
    </el-tree>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed"> 取消 </el-button>
        <el-button type="primary" @click="onConfirm"> 确认 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import {
  permissionListAPI,
  roleOwnershipPermissionsAPI,
  distributePermission
} from '@/api/organization'
import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  roleId: {
    type: String
  }
})
const emits = defineEmits(['update:modelValue'])

/**
  确定按钮点击事件
 */
const onConfirm = async () => {
  await distributePermission({
    roleId: props.roleId,
    menuId: treeRef.value.getCheckedKeys().toString()
  })
  ElMessage.success('角色权限分配成功')
  closed()
}

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}

const treeRef = ref(null)
const defaultProps = {
  children: 'children',
  label: 'name'
}

// 权限树形结构
const allPermission = ref([])
const getPermissionList = async () => {
  allPermission.value = await permissionListAPI()
}
getPermissionList()

// 角色拥有权限
watch(
  () => props.roleId,
  (val) => {
    if (val) onroleOwnershipPermissions()
  }
)

const onroleOwnershipPermissions = async () => {
  const { menu } = await roleOwnershipPermissionsAPI({
    roleId: props.roleId
  })
  if (!menu.length) {
    treeRef.value.setCheckedKeys([])
  } else {
    treeRef.value.setCheckedKeys(menu.map((item) => item.menuId))
  }
}
</script>
